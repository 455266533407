.toolbar-ios {
  padding-top: env(safe-area-inset-top);
}

/* Create a checkmark/indicator */
.checkmark:after {
  content: '';
  display: block;
  position: relative;
  left: 8px;
  top: 3px;
  width: 9px;
  height: 15px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
