/* NOTE: Rubik italic 400 */

@font-face {
    font-display: swap;
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 400;
    src: url('./fonts/Rubik-Regular.woff2') format('woff2');
}

/* NOTE: Rubik italic 500 */

@font-face {
    font-display: swap;
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 500;
    src: url('./fonts/Rubik-Medium.woff2') format('woff2');
}


/* NOTE: Rubik normal 400 */

@font-face {
    font-display: swap;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    src: url('./fonts/Rubik-Regular.woff2') format('woff2');
}

/* NOTE: Rubik normal 500 */

@font-face {
    font-display: swap;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    src: url('./fonts/Rubik-Medium.woff2') format('woff2');
}
